@tailwind base;
@tailwind components;
@tailwind utilities;

.clay {
  background: rgba(31, 45, 71, 0.8);
  box-shadow: inset -0.6em -0.6em 1em rgba(16, 24, 38, 0.8),
    inset 0.4em 0.4em 0.5em rgba(30, 30, 30, 0.6),
    0.8em 0.8em 2em rgba(16, 24, 38, 0.8);
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 18px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #a855f7;
  border-radius: 20px;
  border: 7px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

::-moz-selection {
  background: #a855f7;
}
::-webkit-selection {
  background: #a855f7;
}
::selection {
  background: #a855f7;
}
.tooltip {
  border-radius: 0.25rem;
  background: #26313c;
  color: #fff;
  padding: 1rem;
  box-shadow: 15px 30px 40px 5px rgba(0, 0, 0, 0.5);
  text-align: center;
}

/* hiding first and last lines in cartesian grid */
.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}

.nav-link {
  position: relative;
  text-decoration: none;
}

.nav-link::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: rgb(124, 58, 237);
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.nav-link:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.active {
  color: rgb(157, 101, 255);
  position: relative;
  text-decoration: none;
}
.active::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: rgb(124, 58, 237);
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.active:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

/* Quill Editor */
/* Set content for font-families */
.ql-font-arial,
.ql-font span[data-value='arial']::before {
  font-family: Arial, sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value='comic-sans']::before {
  font-family: 'Comic Sans MS', cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value='courier-new']::before {
  font-family: 'Courier New';
}
.ql-font-georgia,
.ql-font span[data-value='georgia']::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value='helvetica']::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-Inter,
.ql-font span[data-value='Inter']::before {
  font-family: 'Inter', sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value='lucida']::before {
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
}

/* Set content for sizes */
.ql-size-extra-small,
.ql-size span[data-value='extra-small']::before {
  font-size: 13px !important;
}
.ql-size-small,
.ql-size span[data-value='small']::before {
  font-size: 14px !important;
}
.ql-size-medium,
.ql-size span[data-value='medium']::before {
  font-size: 18px !important;
}
.ql-size-large,
.ql-size span[data-value='large']::before {
  font-size: 20px !important;
}

.ql-editor {
  min-height: 50vh;
  font-size: 16px;
}
.App .ql-snow .ql-tooltip.ql-flip {
  width: auto;
  left: 17px !important;
  white-space: normal;
}

@page {
  margin: 1in;
}

@media print {
  body {
    background: none;
  }
  .container .ql-editor {
    width: 6.5in;
    height: 9in;
    padding: 0;
    margin: 0;
    box-shadow: none;
    align-self: flex-start;
  }
  .container .ql-toolbar.ql-snow {
    display: none;
  }
}
